import { FC } from 'react'
import tw from 'twin.macro'

import Close from 'assets/icons/close.svg'
import Reload from 'assets/icons/reload.svg'
import ProgressBar from 'components/RFQTile/ProgressBar'
import { useProgress } from 'hooks/RFQ'

const Container = tw.div`flex gap-2 justify-center items-center`
const TimeRemaining = tw.div`font-ringside-narrow text-[12px] text-light-400`

export interface QuoteStatusProps {
  'data-test': string
  validUntilTime: number
  onClose?(): void
  onReload?(): void
}

export const QuoteStatus: FC<QuoteStatusProps> = ({
  'data-test': dataTest = 'quote-status',
  validUntilTime,
  onClose,
  onReload,
}) => {
  const [progress, secondsRemaining] = useProgress(validUntilTime)

  return (
    <Container data-test={`${dataTest}.container`}>
      <TimeRemaining data-test={`${dataTest}.remaining`}>
        {secondsRemaining}s
      </TimeRemaining>
      <ProgressBar
        data-test={`${dataTest}.progress`}
        variant="RFQ"
        percentage={progress}
      />
      <button data-test={`${dataTest}.reload`} onClick={onReload}>
        <Reload tw="w-[16px] h-[16px]" />
      </button>
      <button data-test={`${dataTest}.close`} onClick={onClose}>
        <Close tw="w-[16px] h-[16px] fill-gray-500" />
      </button>
    </Container>
  )
}
