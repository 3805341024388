import { useState, useEffect } from 'react'

type UseProgressSig = [number, number]

export const useProgress = (validUntilTime: number): UseProgressSig => {
  const [progress, setProgress] = useState<number>(0)
  const [secondsRemaining, setSecondsRemaining] = useState<number>(1)

  useEffect(() => {
    if (!validUntilTime) {
      setSecondsRemaining(0)
      setProgress(0)
      return
    }

    const initialDifference = Math.max(validUntilTime - Date.now(), 1)

    const interval = window.setInterval(() => {
      const difference = validUntilTime - Date.now()
      const elapsed = initialDifference - difference
      if (elapsed >= initialDifference) {
        window.clearInterval(interval)
      }
      setSecondsRemaining(Math.ceil(difference / 1000))
      setProgress(elapsed / initialDifference)
    }, 50)

    return () => window.clearInterval(interval)
  }, [validUntilTime, setProgress])

  return [progress, secondsRemaining]
}
