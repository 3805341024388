import { useCallback } from 'react'

import { useIsConnected } from 'hooks/useIsConnected'
import { useSocket } from 'hooks/useSocket'
import { createReqId } from 'lib/app-util'
import { QuoteRequestData, QuoteRequestMessage } from 'types/api/quote-request'

export const useQuoteExecutor = () => {
  const socket = useSocket()
  const isConnected = useIsConnected()
  const requestQuote = useCallback(
    (quoteRequestData: QuoteRequestData) => {
      const quoteRequest: QuoteRequestMessage = {
        reqid: createReqId(),
        type: 'QuoteRequest',
        data: [quoteRequestData],
      }

      const quoteRequestMessage = JSON.stringify(quoteRequest)

      if (isConnected) {
        socket.send(quoteRequestMessage)
      }
    },
    [socket, isConnected],
  )

  return requestQuote
}
