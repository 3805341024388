import styled from 'styled-components'
import tw from 'twin.macro'

import Button from 'components/Button'
import DeletableContainer from 'components/DeletableContainer'

interface ButtonProps {
  selected: boolean
}

interface WrapperProps {
  isDragging: boolean
}

export const Wrapper = styled(DeletableContainer)<WrapperProps>`
  ${tw`
    min-w-[308px]
    max-w-min
    bg-light-800
    border
    border-solid
    border-light-700
    border-radius[4px]
    p-2
    flex
    flex-col
    gap-2
    `}

  ${({ isDragging }) =>
    !isDragging && tw`hover:border-light-500 focus:border-light-500`}
`

export const QuoteTypeButtonContainer = tw.div`
  flex
  gap-[8px]
  justify-center
  items-center 
  font-ringside-narrow
  font-weight[300]
  text-[12px] 
  text-white
 `

export const QuoteTypeButton = styled.button<ButtonProps>`
  ${tw`bg-transparent px-[8px] pt-[3px] pb-[4px] w-[94px]`}
  ${({ selected }) =>
    selected ? tw`bg-[rgba(255, 255, 255, 0.08)] rounded-[4px]` : ''}
`

export const RequestQuoteButton = tw(Button)`
  flex
  justify-center
`
export const PriceButtonContainer = tw.div`
  flex
  gap-2
  justify-center
  items-center
  w-full
`
