import { map } from 'lodash'
import React from 'react'
import { useRecoilValue } from 'recoil'
import tw from 'twin.macro'

import RFQTileSlotConnector from 'components/RFQTileSlotConnector'
import store from 'store'
import { QuoteRequestData } from 'types/api/quote-request'

const Container = tw.div`flex gap-2 flex-wrap`

export interface RFQTileGroupProps {
  'data-test': string
  groupId: string
  mockQuote?: (request: QuoteRequestData) => void
}

export const RFQTileGroup: React.FC<RFQTileGroupProps> = ({
  'data-test': dataTest = 'rfq-tile-group',
  groupId,
  mockQuote,
}) => {
  const group = useRecoilValue(store.rfqTiles.groupById(groupId))

  return (
    <Container data-test={`${dataTest}.container`}>
      {map(group, (_, index) => (
        <RFQTileSlotConnector
          data-test={`${dataTest}.connector`}
          key={index}
          position={index}
          groupId={groupId}
          mockQuote={mockQuote}
        />
      ))}
    </Container>
  )
}
