import { FC, useState, Fragment, useMemo, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { CurrencyPairHeader } from 'components/CurrencyPairHeader/CurrencyPairHeader'
import NotificationList from 'components/NotificationList'
import QuoteStatus from 'components/RFQTile/QuoteStatus'
import PriceButton from 'components/StreamingTile/PriceButton' // TBD hoist to components/PriceButton
import TradeAmount from 'components/StreamingTile/TradeAmount' // TBD hoist to components/TradeAmount
import { useAvailableBalance } from 'hooks/balance'
import { useQuoteRequest, useTradeExecutor } from 'hooks/RFQ'
import { useHandleTradeAmount } from 'hooks/streamingTiles/useHandleTradeAmount'
import { useOrderStatus } from 'hooks/trade/useOrderStatus'
import { useNotifications } from 'hooks/useNotifications'
import store from 'store'
import { QuoteRequestData } from 'types/api/quote-request'
import { QuoteResponseData } from 'types/api/quote-response'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'
import { QuoteType } from 'types/quote-type'

import ExpiryNotification from './ExpiryNotification'
import {
  Wrapper,
  QuoteTypeButton,
  QuoteTypeButtonContainer,
  RequestQuoteButton,
  PriceButtonContainer,
} from './RFQTile.styles'

export interface RFQTileProps {
  'data-test': string
  currencyPair: CurrencyPair
  isDragging?: boolean
  mockQuote?(request: QuoteRequestData): void
  onBaseCurrencyChange?(baseCurrency: Currency): void
  onQuotedCurrencyChange?(quotedCurrency: Currency): void
  removeSelf?: () => void
}

export const RFQTile: FC<RFQTileProps> = ({
  'data-test': dataTest = 'rfq-tile',
  currencyPair,
  isDragging,
  mockQuote,
  onBaseCurrencyChange: handleBaseCurrencyChange,
  onQuotedCurrencyChange: handleQuotedCurrencyChange,
  removeSelf,
}) => {
  const [baseCurrency, quotedCurrency] = currencyPair
  const [quoteType, setQuoteType] = useState<QuoteType>('Sell')
  const [tradeAmount, setTradeAmount] = useState<string>('')
  const [tradeAmountError, setTradeAmountError] = useState<string>('')
  const [currency, setCurrency] = useState<Currency>(baseCurrency)
  const notificationAnchor = useRef(null)
  const [requestQuote, clearQuote, quoteID] = useQuoteRequest(
    currencyPair,
    currency,
    tradeAmount,
    quoteType,
    mockQuote,
  )
  const quote = useRecoilValue<QuoteResponseData>(
    store.quoteResponseState(quoteID),
  )

  const { notifications, add, remove } = useNotifications()
  const [baseCurrencyAvailable, quotedCurrencyAvailable] =
    useAvailableBalance(currencyPair)
  const [buy, sell, orderId] = useTradeExecutor(quote, clearQuote)

  useOrderStatus(orderId, add)

  const handleAmountChange = useHandleTradeAmount(
    currencyPair,
    currency,
    baseCurrencyAvailable,
    quotedCurrencyAvailable,
    setTradeAmount,
    setTradeAmountError,
  )

  const [setBuy, setSell, setTwoWay] = useMemo(() => {
    const setBuy = () => setQuoteType('Buy')
    const setSell = () => setQuoteType('Sell')
    const setTwoWay = () => setQuoteType('Two-Way')
    return [setBuy, setSell, setTwoWay]
  }, [setQuoteType])

  return (
    <Wrapper
      data-test={`${dataTest}.container`}
      isDragging={isDragging}
      onDeleteClick={removeSelf}
    >
      <div ref={notificationAnchor}></div>
      <CurrencyPairHeader
        data-test={`${dataTest}.currency-pair-header`}
        baseCurrency={baseCurrency}
        quotedCurrency={quotedCurrency}
        setBaseCurrency={handleBaseCurrencyChange}
        setQuotedCurrency={handleQuotedCurrencyChange}
      />
      <QuoteTypeButtonContainer>
        <QuoteTypeButton
          data-test={`${dataTest}.quote-sell`}
          selected={quoteType === 'Sell'}
          onClick={setSell}
        >
          Sell
        </QuoteTypeButton>
        <QuoteTypeButton
          data-test={`${dataTest}.quote-two-way`}
          selected={quoteType === 'Two-Way'}
          onClick={setTwoWay}
        >
          Two-Way
        </QuoteTypeButton>
        <QuoteTypeButton
          data-test={`${dataTest}.quote-buy`}
          selected={quoteType === 'Buy'}
          onClick={setBuy}
        >
          Buy
        </QuoteTypeButton>
      </QuoteTypeButtonContainer>
      <TradeAmount
        data-test={`${dataTest}.trade-amount`}
        error={tradeAmountError}
        baseCurrency={baseCurrency}
        quotedCurrency={quotedCurrency}
        maxAvailableBase={baseCurrencyAvailable}
        maxAvailableQuoted={quotedCurrencyAvailable}
        amount={tradeAmount}
        onAmountChange={handleAmountChange}
        onCurrencyChange={setCurrency}
      />
      {quote && quote.Side === 'Sell' && (
        <PriceButton
          data-test={`${dataTest}.price-side-sell`}
          onConfirm={sell}
          side="sell"
          price={quote.BidPx}
        />
      )}
      {quote && quote.Side === 'Buy' && (
        <PriceButton
          data-test={`${dataTest}.price-side-buy`}
          onConfirm={buy}
          side="buy"
          price={quote.OfferPx}
        />
      )}
      <PriceButtonContainer>
        {quote && !quote.Side && (
          <Fragment>
            <PriceButton
              data-test={`${dataTest}.price-sell`}
              onConfirm={sell}
              side="sell"
              price={quote.BidPx}
            />
            <PriceButton
              data-test={`${dataTest}.price-buy`}
              onConfirm={buy}
              side="buy"
              price={quote.OfferPx}
            />
          </Fragment>
        )}
      </PriceButtonContainer>
      {!quote && (
        <RequestQuoteButton
          data-test={`${dataTest}.quote-request`}
          disabled={tradeAmount.length === 0 || tradeAmountError.length > 0}
          onClick={requestQuote}
        >
          Request Quote
        </RequestQuoteButton>
      )}
      {quote && (
        <QuoteStatus
          data-test={`${dataTest}.quote-status`}
          onReload={requestQuote}
          onClose={clearQuote}
          validUntilTime={Date.parse(quote.ValidUntilTime)}
        />
      )}
      {quote && Date.now() >= Date.parse(quote.EndTime) && (
        <ExpiryNotification
          data-test={`${dataTest}.expiry-notification`}
          onDiscard={clearQuote}
          onRefresh={requestQuote}
        />
      )}
      <NotificationList
        data-test={`${dataTest}.notification-list`}
        notifications={notifications}
        referenceElement={notificationAnchor.current}
        onCloseNotification={remove}
      />
    </Wrapper>
  )
}
