import { useState, useCallback, useMemo } from 'react'

import { useIsConnected } from 'hooks/useIsConnected'
import { useSocket } from 'hooks/useSocket'
import { createRFQOrderMessage } from 'lib/trades'
import { NewOrderSingleMessage } from 'types/api/new-order-single'
import { QuoteResponseData } from 'types/api/quote-response'

type Callback = () => void
type UseTradeExecutorSig = [Callback, Callback, string]

export const useTradeExecutor = (
  quoteResponse: QuoteResponseData | null,
  clearQuote: () => void,
): UseTradeExecutorSig => {
  const [id, setId] = useState<string>('')
  const socket = useSocket()
  const isConnected = useIsConnected()

  const makeTrade = useCallback(
    (side: 'Buy' | 'Sell') => {
      if (!quoteResponse) return

      const message: NewOrderSingleMessage = createRFQOrderMessage(
        quoteResponse,
        side,
      )

      setId(message.data[0].ClOrdID)

      if (isConnected) {
        socket.send(JSON.stringify(message))
      }

      clearQuote()
    },
    [quoteResponse, socket, setId, clearQuote, isConnected],
  )

  const [buy, sell] = useMemo(() => {
    const buy = () => makeTrade('Buy')
    const sell = () => makeTrade('Sell')
    return [buy, sell]
  }, [makeTrade])

  return [buy, sell, id]
}
