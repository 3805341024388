import { FC, useMemo } from 'react'
import tw from 'twin.macro'

export const RFQContainer = tw.div`
h-[6px] 
bg-gray-600 
w-full
 rounded-[10px]
 `

export const RFQFill = tw.div`
    h-full
    bg-[#191919]
    rounded-[10px]
`

const BlotterContainer = tw.div`
  h-[6px] 
  bg-light-900
  w-full
  rounded-[10px]
`

const BlotterFill = tw.div`
    h-full
    bg-light-200
    rounded-[10px]
`

export interface ProgressBarProps {
  'data-test': string
  percentage: number
  opacity?: boolean
  variant: 'RFQ' | 'Blotter'
}

export const ProgressBar: FC<ProgressBarProps> = ({
  'data-test': dataTest = 'progress-bar',
  percentage,
  opacity,
  variant,
}) => {
  const style = useMemo(
    () => ({
      width: `${percentage * 100}%`,
      opacity: opacity ? `${percentage}` : '1.0',
    }),
    [percentage, opacity],
  )

  const [Container, Fill] = useMemo(
    () =>
      variant === 'Blotter'
        ? [BlotterContainer, BlotterFill]
        : [RFQContainer, RFQFill],
    [variant],
  )

  return (
    <Container data-test={`${dataTest}.container`}>
      <Fill data-test={`${dataTest}.bar`} style={style} />
    </Container>
  )
}
