import { FC, useCallback, useEffect, useMemo } from 'react'
import { useDrag } from 'react-dnd'
import { useRecoilState, useSetRecoilState } from 'recoil'

import RFQTile from 'components/RFQTile'
import { useRemoveTile } from 'hooks/streamingTiles/useRemoveTile'
import { DraggableItemTypes } from 'lib/draggable-item-types'
import store from 'store'
import { QuoteRequestData } from 'types/api/quote-request'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

export interface RFQTileSlotConnectorProps {
  'data-test': string
  position: number
  groupId: string
  mockQuote?: (request: QuoteRequestData) => void
}

export const RFQTileSlotConnector: FC<RFQTileSlotConnectorProps> = ({
  'data-test': dataTest = 'rfq-tile-slot-connector',
  position,
  groupId,
  mockQuote,
}) => {
  const groupPosition = useMemo(
    () => ({ position, groupId }),
    [position, groupId],
  )
  const [currencyPair, setCurrencyPair] = useRecoilState(
    store.rfqTiles.currencyPairByPosition(groupPosition),
  )
  const setTileBeingDragged = useSetRecoilState(
    store.tileSlice.tileBeingDragged,
  )
  const removeTile = useRemoveTile()
  const [{ isDragging }, drag] = useDrag(() => ({
    type: DraggableItemTypes.TILE,
    item: { position: groupPosition.position },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{ position: number }>()
      if (item && dropResult) {
        removeTile(item.position)
      }
      setTileBeingDragged(-1)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))
  const removeSelf = useCallback(
    () => removeTile(position),
    [removeTile, position],
  )

  const handleBaseCurrencyChange = useCallback(
    (baseCurrency: Currency) => {
      const newCurrencyPair = [...currencyPair] as CurrencyPair
      newCurrencyPair[0] = baseCurrency
      setCurrencyPair(newCurrencyPair)
    },
    [setCurrencyPair, currencyPair],
  )

  const handleQuotedCurrencyChange = useCallback(
    (quotedCurrency: Currency) => {
      const newCurrencyPair = [...currencyPair] as CurrencyPair
      newCurrencyPair[1] = quotedCurrency
      setCurrencyPair(newCurrencyPair)
    },
    [setCurrencyPair, currencyPair],
  )

  useEffect(() => {
    if (isDragging) setTileBeingDragged(groupPosition.position)
  }, [isDragging, groupPosition, setTileBeingDragged])

  return (
    <div data-test={`${dataTest}.container`} ref={drag}>
      <RFQTile
        data-test={`${dataTest}.rfq-tile`}
        currencyPair={currencyPair}
        onBaseCurrencyChange={handleBaseCurrencyChange}
        onQuotedCurrencyChange={handleQuotedCurrencyChange}
        isDragging={isDragging}
        mockQuote={mockQuote}
        removeSelf={removeSelf}
      />
    </div>
  )
}
