import { FC } from 'react'
import tw from 'twin.macro'

const Container = tw.div`flex justify-between p-3 rounded font-ringside-narrow absolute top-2 bg-gray-900 w-[95%]`
const TitleContainer = tw.div`flex flex-col  text-[14px] font-weight[350] text-white`
const ActionContainer = tw.div`flex flex-col text-[12px] font-weight[300] text-gray-200 items-end gap-1.5`

export interface ExpiryNotificationProps {
  'data-test': string
  onRefresh?(): void
  onDiscard?(): void
}

export const ExpiryNotification: FC<ExpiryNotificationProps> = ({
  'data-test': dataTest = 'expiry-notification',
  onRefresh,
  onDiscard,
}) => {
  return (
    <Container data-test={`${dataTest}.container`}>
      <TitleContainer>
        <div>Quote</div>
        <div>Expired</div>
      </TitleContainer>
      <ActionContainer>
        <button data-test={`${dataTest}.refresh`} onClick={onRefresh}>
          Refresh Quote
        </button>
        <button data-test={`${dataTest}.discard`} onClick={onDiscard}>
          Discard
        </button>
      </ActionContainer>
    </Container>
  )
}
