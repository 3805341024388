import { useCallback, useState } from 'react'

import identify from 'lib/utils/identify'
import { QuoteRequestData } from 'types/api/quote-request'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'
import { QuoteType } from 'types/quote-type'

import { useQuoteExecutor } from './useQuoteExecutor'

export type UseQuoteRequestSig = [() => void, () => void, string]

export const useQuoteRequest = (
  currencyPair: CurrencyPair,
  currency: Currency,
  amount: string,
  quoteType: QuoteType,
  mockQuote?: (request: QuoteRequestData) => void,
): UseQuoteRequestSig => {
  const [id, setID] = useState<string>('')
  const sendQuote = useQuoteExecutor()

  const makeQuote = useCallback(() => {
    const uuid = crypto.randomUUID()
    const [baseCurrency, quotedCurrency] = currencyPair

    const quoteRequest: QuoteRequestData = {
      QuoteReqID: uuid,
      OrderQty: amount,
      Symbol: identify.currencyPair([baseCurrency, quotedCurrency]),
      TransactTime: new Date().toISOString(),
      Currency: currency.Symbol,
    }

    if (quoteType !== 'Two-Way') {
      // two way quotes do not specify a side
      Object.assign(quoteRequest, { Side: quoteType })
    }

    setID(uuid)

    if (mockQuote) mockQuote(quoteRequest)
    else sendQuote(quoteRequest)
  }, [currencyPair, amount, quoteType, mockQuote, currency, sendQuote])

  const clearQuote = useCallback(() => setID(''), [setID])

  return [makeQuote, clearQuote, id]
}
